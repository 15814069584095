body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'sans-serif';
  background-color: #046B99;
  color: #FFFFFF;
}

main { 
  height: 50vh;
  width: 80vw;
  margin: 20vh 10vw;
}

footer{
  margin: 0;
  height: 5vh;
  width: 100vw;
  font-size: small;
}

a, a:visited {
  color: #FFFFFF;
}